<template>
  <v-btn
    v-on="action ? { click: action } : {}"
    v-bind="attrs"
    :to="to"
    :class="[buttonClasses, cssClasses, 'main_text_color--text']"
    :outlined="outlined"
    :loading="loading ? loading : false"
    :elevation="elevation"
  >
    {{ message }}
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'GeneralButton',
  props: {
    attrs: {
      type: Object
    },
    action: {
      type: Function
    },
    to: {
      type: [String, Object]
    },
    loading: {
      type: Boolean
    },
    message: {
      type: String
    },
    size: {
      type: String
    },
    elevation: {
      type: [Number, String],
      default: undefined
    },
    cssClasses: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses () {
      let classes = {
        'v-btn': true,
        'v-btn-medium': this.size === 'medium',
        'v-btn-large': this.size === 'large',
        'v-btn-small': this.size === 'small'
      };

      if (this.outlined) {
      } else if (this.secondary) {
        classes['secondary'] = true;
        classes['text-white'] = true;
      } else if (this.primary) {
        classes['primary'] = true;
      } else if (this.white) {
        classes['white'] = true;
      } else {
        classes['primary'] = true;
      }

      if (this.responsive) {
        classes['responsive-sm'] = this.$vuetify.breakpoint.smAndDown;
      }

      return classes;
    }
  }
};
</script>

<style scoped>
.v-btn {
  border-radius: 16px !important;
  text-transform: unset !important;
}

.v-btn-medium {
  padding: 24px 28px 24px 28px !important;
}

.responsive-sm {
  width: 100%;
}
</style>
